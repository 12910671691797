import { Authority } from '@/shared/security/authority';
/* tslint:disable */
// prettier-ignore
const Entities = () => import('@/entities/entities.vue');

const PreviousOwner = () => import('@/entities/partnerService/previous-owner/previous-owner.vue');
const PreviousOwnerUpdate = () => import('@/entities/partnerService/previous-owner/previous-owner-update.vue');
const PreviousOwnerDetails = () => import('@/entities/partnerService/previous-owner/previous-owner-details.vue');

const CommunicationLanguage = () => import('@/entities/partnerService/communication-language/communication-language.vue');
const CommunicationLanguageUpdate = () => import('@/entities/partnerService/communication-language/communication-language-update.vue');
const CommunicationLanguageDetails = () => import('@/entities/partnerService/communication-language/communication-language-details.vue');

const ActiveProfileFolderCounter = () =>
  import('@/entities/partnerService/active-profile-folder-counter/active-profile-folder-counter.vue');
const ActiveProfileFolderCounterUpdate = () =>
  import('@/entities/partnerService/active-profile-folder-counter/active-profile-folder-counter-update.vue');
const ActiveProfileFolderCounterDetails = () =>
  import('@/entities/partnerService/active-profile-folder-counter/active-profile-folder-counter-details.vue');

const ActiveProfileCounter = () => import('@/entities/partnerService/active-profile-counter/active-profile-counter.vue');
const ActiveProfileCounterUpdate = () => import('@/entities/partnerService/active-profile-counter/active-profile-counter-update.vue');
const ActiveProfileCounterDetails = () => import('@/entities/partnerService/active-profile-counter/active-profile-counter-details.vue');

const ContactDetails = () => import('@/entities/partnerService/contact-details/contact-details.vue');
const ContactDetailsUpdate = () => import('@/entities/partnerService/contact-details/contact-details-update.vue');
const ContactDetailsDetails = () => import('@/entities/partnerService/contact-details/contact-details-details.vue');

const PartnerEmployee = () => import('@/entities/partnerService/partner-employee/partner-employee.vue');
const PartnerEmployeeUpdate = () => import('@/entities/partnerService/partner-employee/partner-employee-update.vue');
const PartnerEmployeeDetails = () => import('@/entities/partnerService/partner-employee/partner-employee-details.vue');

const ApplicantProfileResumeSection = () =>
  import('@/entities/partnerService/applicant-profile-resume-section/applicant-profile-resume-section.vue');
const ApplicantProfileResumeSectionUpdate = () =>
  import('@/entities/partnerService/applicant-profile-resume-section/applicant-profile-resume-section-update.vue');
const ApplicantProfileResumeSectionDetails = () =>
  import('@/entities/partnerService/applicant-profile-resume-section/applicant-profile-resume-section-details.vue');

const CustomerActivity = () => import('@/entities/partnerService/customer-activity/customer-activity.vue');
const CustomerActivityUpdate = () => import('@/entities/partnerService/customer-activity/customer-activity-update.vue');
const CustomerActivityDetails = () => import('@/entities/partnerService/customer-activity/customer-activity-details.vue');

const CustomerCompanySize = () => import('@/entities/partnerService/customer-company-size/customer-company-size.vue');
const CustomerCompanySizeUpdate = () => import('@/entities/partnerService/customer-company-size/customer-company-size-update.vue');
const CustomerCompanySizeDetails = () => import('@/entities/partnerService/customer-company-size/customer-company-size-details.vue');

const CustomerCompany = () => import('@/entities/partnerService/customer-company/customer-company.vue');
const CustomerCompanyUpdate = () => import('@/entities/partnerService/customer-company/customer-company-update.vue');
const CustomerCompanyDetails = () => import('@/entities/partnerService/customer-company/customer-company-details.vue');

const Referer = () => import('@/entities/partnerService/referer/referer.vue');
const RefererUpdate = () => import('@/entities/partnerService/referer/referer-update.vue');
const RefererDetails = () => import('@/entities/partnerService/referer/referer-details.vue');

const ApplicantProfileResumeSectionCategory = () =>
  import('@/entities/partnerService/applicant-profile-resume-section-category/applicant-profile-resume-section-category.vue');
const ApplicantProfileResumeSectionCategoryUpdate = () =>
  import('@/entities/partnerService/applicant-profile-resume-section-category/applicant-profile-resume-section-category-update.vue');
const ApplicantProfileResumeSectionCategoryDetails = () =>
  import('@/entities/partnerService/applicant-profile-resume-section-category/applicant-profile-resume-section-category-details.vue');

const CustomerJobPostingCounter = () => import('@/entities/partnerService/customer-job-posting-counter/customer-job-posting-counter.vue');
const CustomerJobPostingCounterUpdate = () =>
  import('@/entities/partnerService/customer-job-posting-counter/customer-job-posting-counter-update.vue');
const CustomerJobPostingCounterDetails = () =>
  import('@/entities/partnerService/customer-job-posting-counter/customer-job-posting-counter-details.vue');

const TextTemplate = () => import('@/entities/partnerService/text-template/text-template.vue');
const TextTemplateUpdate = () => import('@/entities/partnerService/text-template/text-template-update.vue');
const TextTemplateDetails = () => import('@/entities/partnerService/text-template/text-template-details.vue');

const TextTemplateVariable = () => import('@/entities/partnerService/text-template-variable/text-template-variable.vue');
const TextTemplateVariableUpdate = () => import('@/entities/partnerService/text-template-variable/text-template-variable-update.vue');
const TextTemplateVariableDetails = () => import('@/entities/partnerService/text-template-variable/text-template-variable-details.vue');

const ActiveProfile = () => import('@/entities/partnerService/active-profile/active-profile.vue');
const ActiveProfileUpdate = () => import('@/entities/partnerService/active-profile/active-profile-update.vue');
const ActiveProfileDetails = () => import('@/entities/partnerService/active-profile/active-profile-details.vue');

const ApplicantProfileResumeSectionArea = () =>
  import('@/entities/partnerService/applicant-profile-resume-section-area/applicant-profile-resume-section-area.vue');
const ApplicantProfileResumeSectionAreaUpdate = () =>
  import('@/entities/partnerService/applicant-profile-resume-section-area/applicant-profile-resume-section-area-update.vue');
const ApplicantProfileResumeSectionAreaDetails = () =>
  import('@/entities/partnerService/applicant-profile-resume-section-area/applicant-profile-resume-section-area-details.vue');

const Partner = () => import('@/entities/partnerService/partner/partner.vue');
const PartnerUpdate = () => import('@/entities/partnerService/partner/partner-update.vue');
const PartnerDetails = () => import('@/entities/partnerService/partner/partner-details.vue');

const ActivityType = () => import('@/entities/partnerService/activity-type/activity-type.vue');
const ActivityTypeUpdate = () => import('@/entities/partnerService/activity-type/activity-type-update.vue');
const ActivityTypeDetails = () => import('@/entities/partnerService/activity-type/activity-type-details.vue');

const CustomerReference = () => import('@/entities/partnerService/customer-reference/customer-reference.vue');
const CustomerReferenceUpdate = () => import('@/entities/partnerService/customer-reference/customer-reference-update.vue');
const CustomerReferenceDetails = () => import('@/entities/partnerService/customer-reference/customer-reference-details.vue');

const MaritalStatus = () => import('@/entities/partnerService/marital-status/marital-status.vue');
const MaritalStatusUpdate = () => import('@/entities/partnerService/marital-status/marital-status-update.vue');
const MaritalStatusDetails = () => import('@/entities/partnerService/marital-status/marital-status-details.vue');

const CustomerJobPostingPortal = () => import('@/entities/partnerService/customer-job-posting-portal/customer-job-posting-portal.vue');
const CustomerJobPostingPortalUpdate = () =>
  import('@/entities/partnerService/customer-job-posting-portal/customer-job-posting-portal-update.vue');
const CustomerJobPostingPortalDetails = () =>
  import('@/entities/partnerService/customer-job-posting-portal/customer-job-posting-portal-details.vue');

const Permission = () => import('@/entities/partnerService/permission/permission.vue');
const PermissionUpdate = () => import('@/entities/partnerService/permission/permission-update.vue');
const PermissionDetails = () => import('@/entities/partnerService/permission/permission-details.vue');

const PlacementType = () => import('@/entities/partnerService/placement-type/placement-type.vue');
const PlacementTypeUpdate = () => import('@/entities/partnerService/placement-type/placement-type-update.vue');
const PlacementTypeDetails = () => import('@/entities/partnerService/placement-type/placement-type-details.vue');

const Note = () => import('@/entities/partnerService/note/note.vue');
const NoteUpdate = () => import('@/entities/partnerService/note/note-update.vue');
const NoteDetails = () => import('@/entities/partnerService/note/note-details.vue');

const ApplicantStatus = () => import('@/entities/partnerService/applicant-status/applicant-status.vue');
const ApplicantStatusUpdate = () => import('@/entities/partnerService/applicant-status/applicant-status-update.vue');
const ApplicantStatusDetails = () => import('@/entities/partnerService/applicant-status/applicant-status-details.vue');

const StreamingIpBlacklistEntry = () => import('@/entities/partnerService/streaming-ip-blacklist-entry/streaming-ip-blacklist-entry.vue');
const StreamingIpBlacklistEntryUpdate = () =>
  import('@/entities/partnerService/streaming-ip-blacklist-entry/streaming-ip-blacklist-entry-update.vue');
const StreamingIpBlacklistEntryDetails = () =>
  import('@/entities/partnerService/streaming-ip-blacklist-entry/streaming-ip-blacklist-entry-details.vue');

const ApplicantLanguage = () => import('@/entities/partnerService/applicant-language/applicant-language.vue');
const ApplicantLanguageUpdate = () => import('@/entities/partnerService/applicant-language/applicant-language-update.vue');
const ApplicantLanguageDetails = () => import('@/entities/partnerService/applicant-language/applicant-language-details.vue');

const ApplicantProfile = () => import('@/entities/partnerService/applicant-profile/applicant-profile.vue');
const ApplicantProfileUpdate = () => import('@/entities/partnerService/applicant-profile/applicant-profile-update.vue');
const ApplicantProfileDetails = () => import('@/entities/partnerService/applicant-profile/applicant-profile-details.vue');

const ActiveProfileFolder = () => import('@/entities/partnerService/active-profile-folder/active-profile-folder.vue');
const ActiveProfileFolderUpdate = () => import('@/entities/partnerService/active-profile-folder/active-profile-folder-update.vue');
const ActiveProfileFolderDetails = () => import('@/entities/partnerService/active-profile-folder/active-profile-folder-details.vue');

const Profession = () => import('@/entities/partnerService/profession/profession.vue');
const ProfessionUpdate = () => import('@/entities/partnerService/profession/profession-update.vue');
const ProfessionDetails = () => import('@/entities/partnerService/profession/profession-details.vue');

const MailingBlacklistEntry = () => import('@/entities/partnerService/mailing-blacklist-entry/mailing-blacklist-entry.vue');
const MailingBlacklistEntryUpdate = () => import('@/entities/partnerService/mailing-blacklist-entry/mailing-blacklist-entry-update.vue');
const MailingBlacklistEntryDetails = () => import('@/entities/partnerService/mailing-blacklist-entry/mailing-blacklist-entry-details.vue');

const TextTemplateCategory = () => import('@/entities/partnerService/text-template-category/text-template-category.vue');
const TextTemplateCategoryUpdate = () => import('@/entities/partnerService/text-template-category/text-template-category-update.vue');
const TextTemplateCategoryDetails = () => import('@/entities/partnerService/text-template-category/text-template-category-details.vue');

const TextTemplateArea = () => import('@/entities/partnerService/text-template-area/text-template-area.vue');
const TextTemplateAreaUpdate = () => import('@/entities/partnerService/text-template-area/text-template-area-update.vue');
const TextTemplateAreaDetails = () => import('@/entities/partnerService/text-template-area/text-template-area-details.vue');

const ActiveJobPostingCounter = () => import('@/entities/partnerService/active-job-posting-counter/active-job-posting-counter.vue');
const ActiveJobPostingCounterUpdate = () =>
  import('@/entities/partnerService/active-job-posting-counter/active-job-posting-counter-update.vue');
const ActiveJobPostingCounterDetails = () =>
  import('@/entities/partnerService/active-job-posting-counter/active-job-posting-counter-details.vue');

const ApplicationSettings = () => import('@/entities/partnerService/application-settings/application-settings.vue');
const ApplicationSettingsUpdate = () => import('@/entities/partnerService/application-settings/application-settings-update.vue');
const ApplicationSettingsDetails = () => import('@/entities/partnerService/application-settings/application-settings-details.vue');

const TextTemplateVariableValuePath = () =>
  import('@/entities/partnerService/text-template-variable-value-path/text-template-variable-value-path.vue');
const TextTemplateVariableValuePathUpdate = () =>
  import('@/entities/partnerService/text-template-variable-value-path/text-template-variable-value-path-update.vue');
const TextTemplateVariableValuePathDetails = () =>
  import('@/entities/partnerService/text-template-variable-value-path/text-template-variable-value-path-details.vue');

const ApplicantActivity = () => import('@/entities/partnerService/applicant-activity/applicant-activity.vue');
const ApplicantActivityUpdate = () => import('@/entities/partnerService/applicant-activity/applicant-activity-update.vue');
const ApplicantActivityDetails = () => import('@/entities/partnerService/applicant-activity/applicant-activity-details.vue');

const ExternalJobPostingServiceProviders = () =>
  import('@/entities/partnerService/external-job-posting-service-providers/external-job-posting-service-providers.vue');
const ExternalJobPostingServiceProvidersUpdate = () =>
  import('@/entities/partnerService/external-job-posting-service-providers/external-job-posting-service-providers-update.vue');
const ExternalJobPostingServiceProvidersDetails = () =>
  import('@/entities/partnerService/external-job-posting-service-providers/external-job-posting-service-providers-details.vue');

const CustomerType = () => import('@/entities/partnerService/customer-type/customer-type.vue');
const CustomerTypeUpdate = () => import('@/entities/partnerService/customer-type/customer-type-update.vue');
const CustomerTypeDetails = () => import('@/entities/partnerService/customer-type/customer-type-details.vue');

const Tag = () => import('@/entities/partnerService/tag/tag.vue');
const TagUpdate = () => import('@/entities/partnerService/tag/tag-update.vue');
const TagDetails = () => import('@/entities/partnerService/tag/tag-details.vue');

const TwoFactorAuthWhitelistEntry = () =>
  import('@/entities/partnerService/two-factor-auth-whitelist-entry/two-factor-auth-whitelist-entry.vue');
const TwoFactorAuthWhitelistEntryUpdate = () =>
  import('@/entities/partnerService/two-factor-auth-whitelist-entry/two-factor-auth-whitelist-entry-update.vue');
const TwoFactorAuthWhitelistEntryDetails = () =>
  import('@/entities/partnerService/two-factor-auth-whitelist-entry/two-factor-auth-whitelist-entry-details.vue');

const CustomerPriority = () => import('@/entities/partnerService/customer-priority/customer-priority.vue');
const CustomerPriorityUpdate = () => import('@/entities/partnerService/customer-priority/customer-priority-update.vue');
const CustomerPriorityDetails = () => import('@/entities/partnerService/customer-priority/customer-priority-details.vue');

const ActiveJobPosting = () => import('@/entities/partnerService/active-job-posting/active-job-posting.vue');
const ActiveJobPostingUpdate = () => import('@/entities/partnerService/active-job-posting/active-job-posting-update.vue');
const ActiveJobPostingDetails = () => import('@/entities/partnerService/active-job-posting/active-job-posting-details.vue');

const CustomerContact = () => import('@/entities/partnerService/customer-contact/customer-contact.vue');
const CustomerContactUpdate = () => import('@/entities/partnerService/customer-contact/customer-contact-update.vue');
const CustomerContactDetails = () => import('@/entities/partnerService/customer-contact/customer-contact-details.vue');

const ApplicantProfileType = () => import('@/entities/partnerService/applicant-profile-type/applicant-profile-type.vue');
const ApplicantProfileTypeUpdate = () => import('@/entities/partnerService/applicant-profile-type/applicant-profile-type-update.vue');
const ApplicantProfileTypeDetails = () => import('@/entities/partnerService/applicant-profile-type/applicant-profile-type-details.vue');

const Region = () => import('@/entities/partnerService/region/region.vue');
const RegionUpdate = () => import('@/entities/partnerService/region/region-update.vue');
const RegionDetails = () => import('@/entities/partnerService/region/region-details.vue');

const Applicant = () => import('@/entities/partnerService/applicant/applicant.vue');
const ApplicantUpdate = () => import('@/entities/partnerService/applicant/applicant-update.vue');
const ApplicantDetails = () => import('@/entities/partnerService/applicant/applicant-details.vue');

const CustomerJobPosting = () => import('@/entities/partnerService/customer-job-posting/customer-job-posting.vue');
const CustomerJobPostingUpdate = () => import('@/entities/partnerService/customer-job-posting/customer-job-posting-update.vue');
const CustomerJobPostingDetails = () => import('@/entities/partnerService/customer-job-posting/customer-job-posting-details.vue');

const Nationality = () => import('@/entities/partnerService/nationality/nationality.vue');
const NationalityUpdate = () => import('@/entities/partnerService/nationality/nationality-update.vue');
const NationalityDetails = () => import('@/entities/partnerService/nationality/nationality-details.vue');

const Country = () => import('@/entities/partnerService/country/country.vue');
const CountryUpdate = () => import('@/entities/partnerService/country/country-update.vue');
const CountryDetails = () => import('@/entities/partnerService/country/country-details.vue');

const ApplicantPeriodOfNotice = () => import('@/entities/partnerService/applicant-period-of-notice/applicant-period-of-notice.vue');
const ApplicantPeriodOfNoticeUpdate = () =>
  import('@/entities/partnerService/applicant-period-of-notice/applicant-period-of-notice-update.vue');
const ApplicantPeriodOfNoticeDetails = () =>
  import('@/entities/partnerService/applicant-period-of-notice/applicant-period-of-notice-details.vue');

const ApplicationType = () => import('@/entities/partnerService/application-type/application-type.vue');
const ApplicationTypeUpdate = () => import('@/entities/partnerService/application-type/application-type-update.vue');
const ApplicationTypeDetails = () => import('@/entities/partnerService/application-type/application-type-details.vue');

const AddressDetails = () => import('@/entities/partnerService/address-details/address-details.vue');
const AddressDetailsUpdate = () => import('@/entities/partnerService/address-details/address-details-update.vue');
const AddressDetailsDetails = () => import('@/entities/partnerService/address-details/address-details-details.vue');

const PartnerEmployeeAccountSettings = () =>
  import('@/entities/partnerService/partner-employee-account-settings/partner-employee-account-settings.vue');
const PartnerEmployeeAccountSettingsUpdate = () =>
  import('@/entities/partnerService/partner-employee-account-settings/partner-employee-account-settings-update.vue');
const PartnerEmployeeAccountSettingsDetails = () =>
  import('@/entities/partnerService/partner-employee-account-settings/partner-employee-account-settings-details.vue');
// prettier-ignore
const ApplicantFile = () => import('@/entities/filesApplicant/applicant-file/applicant-file.vue');
// prettier-ignore
const ApplicantFileUpdate = () => import('@/entities/filesApplicant/applicant-file/applicant-file-update.vue');
// prettier-ignore
const ApplicantFileDetails = () => import('@/entities/filesApplicant/applicant-file/applicant-file-details.vue');
// prettier-ignore
const CustomerFile = () => import('@/entities/filesCustomer/customer-file/customer-file.vue');
// prettier-ignore
const CustomerFileUpdate = () => import('@/entities/filesCustomer/customer-file/customer-file-update.vue');
// prettier-ignore
const CustomerFileDetails = () => import('@/entities/filesCustomer/customer-file/customer-file-details.vue');
// prettier-ignore
const PartnerFile = () => import('@/entities/filesPartner/partner-file/partner-file.vue');
// prettier-ignore
const PartnerFileUpdate = () => import('@/entities/filesPartner/partner-file/partner-file-update.vue');
// prettier-ignore
const PartnerFileDetails = () => import('@/entities/filesPartner/partner-file/partner-file-details.vue');
// prettier-ignore
const PartnerEmployeeFile = () => import('@/entities/filesPartner/partner-employee-file/partner-employee-file.vue');
// prettier-ignore
const PartnerEmployeeFileUpdate = () => import('@/entities/filesPartner/partner-employee-file/partner-employee-file-update.vue');
// prettier-ignore
const PartnerEmployeeFileDetails = () => import('@/entities/filesPartner/partner-employee-file/partner-employee-file-details.vue');
// prettier-ignore
const ProfessionFile = () => import('@/entities/filesProfession/profession-file/profession-file.vue');
// prettier-ignore
const ProfessionFileUpdate = () => import('@/entities/filesProfession/profession-file/profession-file-update.vue');
// prettier-ignore
const ProfessionFileDetails = () => import('@/entities/filesProfession/profession-file/profession-file-details.vue');
// prettier-ignore
const Language = () => import('@/entities/partnerService/language/language.vue');
// prettier-ignore
const LanguageUpdate = () => import('@/entities/partnerService/language/language-update.vue');
// prettier-ignore
const LanguageDetails = () => import('@/entities/partnerService/language/language-details.vue');
// prettier-ignore
const LanguageSkill = () => import('@/entities/partnerService/language-skill/language-skill.vue');
// prettier-ignore
const LanguageSkillUpdate = () => import('@/entities/partnerService/language-skill/language-skill-update.vue');
// prettier-ignore
const LanguageSkillDetails = () => import('@/entities/partnerService/language-skill/language-skill-details.vue');
// prettier-ignore
const ActivityFile = () => import('@/entities/filesActivity/activity-file/activity-file.vue');
// prettier-ignore
const ActivityFileUpdate = () => import('@/entities/filesActivity/activity-file/activity-file-update.vue');
// prettier-ignore
const ActivityFileDetails = () => import('@/entities/filesActivity/activity-file/activity-file-details.vue');
// prettier-ignore
const CustomerOrder = () => import('@/entities/partnerService/customer-order/customer-order.vue');
// prettier-ignore
const CustomerOrderUpdate = () => import('@/entities/partnerService/customer-order/customer-order-update.vue');
// prettier-ignore
const CustomerOrderDetails = () => import('@/entities/partnerService/customer-order/customer-order-details.vue');
// prettier-ignore
const DrivingLicense = () => import('@/entities/partnerService/driving-license/driving-license.vue');
// prettier-ignore
const DrivingLicenseUpdate = () => import('@/entities/partnerService/driving-license/driving-license-update.vue');
// prettier-ignore
const DrivingLicenseDetails = () => import('@/entities/partnerService/driving-license/driving-license-details.vue');
// prettier-ignore
const CustomerOrderLanguage = () => import('@/entities/partnerService/customer-order-language/customer-order-language.vue');
// prettier-ignore
const CustomerOrderLanguageUpdate = () => import('@/entities/partnerService/customer-order-language/customer-order-language-update.vue');
// prettier-ignore
const CustomerOrderLanguageDetails = () => import('@/entities/partnerService/customer-order-language/customer-order-language-details.vue');
// prettier-ignore
const MatchingPreset = () => import('@/entities/partnerService/matching-preset/matching-preset.vue');
// prettier-ignore
const MatchingPresetUpdate = () => import('@/entities/partnerService/matching-preset/matching-preset-update.vue');
// prettier-ignore
const MatchingPresetDetails = () => import('@/entities/partnerService/matching-preset/matching-preset-details.vue');
// prettier-ignore
const MatchingPresetApplicantLanguage = () => import('@/entities/partnerService/matching-preset-applicant-language/matching-preset-applicant-language.vue');
// prettier-ignore
const MatchingPresetApplicantLanguageUpdate = () => import('@/entities/partnerService/matching-preset-applicant-language/matching-preset-applicant-language-update.vue');
// prettier-ignore
const MatchingPresetApplicantLanguageDetails = () => import('@/entities/partnerService/matching-preset-applicant-language/matching-preset-applicant-language-details.vue');
// prettier-ignore
const BfaApiSettings = () => import('@/entities/externalBundesagentur/bfa-api-settings/bfa-api-settings.vue');
// prettier-ignore
const BfaApiSettingsUpdate = () => import('@/entities/externalBundesagentur/bfa-api-settings/bfa-api-settings-update.vue');
// prettier-ignore
const BfaApiSettingsDetails = () => import('@/entities/externalBundesagentur/bfa-api-settings/bfa-api-settings-details.vue');
// prettier-ignore
const BfaCompetence = () => import('@/entities/externalBundesagentur/bfa-competence/bfa-competence.vue');
// prettier-ignore
const BfaCompetenceUpdate = () => import('@/entities/externalBundesagentur/bfa-competence/bfa-competence-update.vue');
// prettier-ignore
const BfaCompetenceDetails = () => import('@/entities/externalBundesagentur/bfa-competence/bfa-competence-details.vue');
// prettier-ignore
const BfaCoopPartner = () => import('@/entities/externalBundesagentur/bfa-coop-partner/bfa-coop-partner.vue');
// prettier-ignore
const BfaCoopPartnerUpdate = () => import('@/entities/externalBundesagentur/bfa-coop-partner/bfa-coop-partner-update.vue');
// prettier-ignore
const BfaCoopPartnerDetails = () => import('@/entities/externalBundesagentur/bfa-coop-partner/bfa-coop-partner-details.vue');
// prettier-ignore
const BfaKindOfApplication = () => import('@/entities/externalBundesagentur/bfa-kind-of-application/bfa-kind-of-application.vue');
// prettier-ignore
const BfaKindOfApplicationUpdate = () => import('@/entities/externalBundesagentur/bfa-kind-of-application/bfa-kind-of-application-update.vue');
// prettier-ignore
const BfaKindOfApplicationDetails = () => import('@/entities/externalBundesagentur/bfa-kind-of-application/bfa-kind-of-application-details.vue');
// prettier-ignore
const BfaLeaderShip = () => import('@/entities/externalBundesagentur/bfa-leader-ship/bfa-leader-ship.vue');
// prettier-ignore
const BfaLeaderShipUpdate = () => import('@/entities/externalBundesagentur/bfa-leader-ship/bfa-leader-ship-update.vue');
// prettier-ignore
const BfaLeaderShipDetails = () => import('@/entities/externalBundesagentur/bfa-leader-ship/bfa-leader-ship-details.vue');
// prettier-ignore
const BfaPartnerInfo = () => import('@/entities/externalBundesagentur/bfa-partner-info/bfa-partner-info.vue');
// prettier-ignore
const BfaPartnerInfoUpdate = () => import('@/entities/externalBundesagentur/bfa-partner-info/bfa-partner-info-update.vue');
// prettier-ignore
const BfaPartnerInfoDetails = () => import('@/entities/externalBundesagentur/bfa-partner-info/bfa-partner-info-details.vue');
// prettier-ignore
const BfaRegion = () => import('@/entities/externalBundesagentur/bfa-region/bfa-region.vue');
// prettier-ignore
const BfaRegionUpdate = () => import('@/entities/externalBundesagentur/bfa-region/bfa-region-update.vue');
// prettier-ignore
const BfaRegionDetails = () => import('@/entities/externalBundesagentur/bfa-region/bfa-region-details.vue');
// prettier-ignore
const BfaSalutation = () => import('@/entities/externalBundesagentur/bfa-salutation/bfa-salutation.vue');
// prettier-ignore
const BfaSalutationUpdate = () => import('@/entities/externalBundesagentur/bfa-salutation/bfa-salutation-update.vue');
// prettier-ignore
const BfaSalutationDetails = () => import('@/entities/externalBundesagentur/bfa-salutation/bfa-salutation-details.vue');
// prettier-ignore
const BfaWorkplace = () => import('@/entities/externalBundesagentur/bfa-workplace/bfa-workplace.vue');
// prettier-ignore
const BfaWorkplaceUpdate = () => import('@/entities/externalBundesagentur/bfa-workplace/bfa-workplace-update.vue');
// prettier-ignore
const BfaWorkplaceDetails = () => import('@/entities/externalBundesagentur/bfa-workplace/bfa-workplace-details.vue');
// prettier-ignore
const BfaJobPosting = () => import('@/entities/externalBundesagentur/bfa-job-posting/bfa-job-posting.vue');
// prettier-ignore
const BfaJobPostingUpdate = () => import('@/entities/externalBundesagentur/bfa-job-posting/bfa-job-posting-update.vue');
// prettier-ignore
const BfaJobPostingDetails = () => import('@/entities/externalBundesagentur/bfa-job-posting/bfa-job-posting-details.vue');
// prettier-ignore
const BfaVamProfession = () => import('@/entities/externalBundesagentur/bfa-vam-profession/bfa-vam-profession.vue');
// prettier-ignore
const BfaVamProfessionUpdate = () => import('@/entities/externalBundesagentur/bfa-vam-profession/bfa-vam-profession-update.vue');
// prettier-ignore
const BfaVamProfessionDetails = () => import('@/entities/externalBundesagentur/bfa-vam-profession/bfa-vam-profession-details.vue');
// prettier-ignore
const BfaAuthority = () => import('@/entities/externalBundesagentur/bfa-authority/bfa-authority.vue');
// prettier-ignore
const BfaAuthorityUpdate = () => import('@/entities/externalBundesagentur/bfa-authority/bfa-authority-update.vue');
// prettier-ignore
const BfaAuthorityDetails = () => import('@/entities/externalBundesagentur/bfa-authority/bfa-authority-details.vue');
// prettier-ignore
const BfaBudgetResponsibility = () => import('@/entities/externalBundesagentur/bfa-budget-responsibility/bfa-budget-responsibility.vue');
// prettier-ignore
const BfaBudgetResponsibilityUpdate = () => import('@/entities/externalBundesagentur/bfa-budget-responsibility/bfa-budget-responsibility-update.vue');
// prettier-ignore
const BfaBudgetResponsibilityDetails = () => import('@/entities/externalBundesagentur/bfa-budget-responsibility/bfa-budget-responsibility-details.vue');
// prettier-ignore
const BfaDegree = () => import('@/entities/externalBundesagentur/bfa-degree/bfa-degree.vue');
// prettier-ignore
const BfaDegreeUpdate = () => import('@/entities/externalBundesagentur/bfa-degree/bfa-degree-update.vue');
// prettier-ignore
const BfaDegreeDetails = () => import('@/entities/externalBundesagentur/bfa-degree/bfa-degree-details.vue');
// prettier-ignore
const BfaEmployeeResponsibility = () => import('@/entities/externalBundesagentur/bfa-employee-responsibility/bfa-employee-responsibility.vue');
// prettier-ignore
const BfaEmployeeResponsibilityUpdate = () => import('@/entities/externalBundesagentur/bfa-employee-responsibility/bfa-employee-responsibility-update.vue');
// prettier-ignore
const BfaEmployeeResponsibilityDetails = () => import('@/entities/externalBundesagentur/bfa-employee-responsibility/bfa-employee-responsibility-details.vue');
// prettier-ignore
const BfaErrorCodeMapping = () => import('@/entities/externalBundesagentur/bfa-error-code-mapping/bfa-error-code-mapping.vue');
// prettier-ignore
const BfaErrorCodeMappingUpdate = () => import('@/entities/externalBundesagentur/bfa-error-code-mapping/bfa-error-code-mapping-update.vue');
// prettier-ignore
const BfaErrorCodeMappingDetails = () => import('@/entities/externalBundesagentur/bfa-error-code-mapping/bfa-error-code-mapping-details.vue');
// prettier-ignore
const BfaHandicap = () => import('@/entities/externalBundesagentur/bfa-handicap/bfa-handicap.vue');
// prettier-ignore
const BfaHandicapUpdate = () => import('@/entities/externalBundesagentur/bfa-handicap/bfa-handicap-update.vue');
// prettier-ignore
const BfaHandicapDetails = () => import('@/entities/externalBundesagentur/bfa-handicap/bfa-handicap-details.vue');
// prettier-ignore
const BfaJobPostingDrivingLicense = () => import('@/entities/externalBundesagentur/bfa-job-posting-driving-license/bfa-job-posting-driving-license.vue');
// prettier-ignore
const BfaJobPostingDrivingLicenseUpdate = () => import('@/entities/externalBundesagentur/bfa-job-posting-driving-license/bfa-job-posting-driving-license-update.vue');
// prettier-ignore
const BfaJobPostingDrivingLicenseDetails = () => import('@/entities/externalBundesagentur/bfa-job-posting-driving-license/bfa-job-posting-driving-license-details.vue');
// prettier-ignore
const BfaJobPostingHardSkill = () => import('@/entities/externalBundesagentur/bfa-job-posting-hard-skill/bfa-job-posting-hard-skill.vue');
// prettier-ignore
const BfaJobPostingHardSkillUpdate = () => import('@/entities/externalBundesagentur/bfa-job-posting-hard-skill/bfa-job-posting-hard-skill-update.vue');
// prettier-ignore
const BfaJobPostingHardSkillDetails = () => import('@/entities/externalBundesagentur/bfa-job-posting-hard-skill/bfa-job-posting-hard-skill-details.vue');
// prettier-ignore
const BfaJobPostingLanguage = () => import('@/entities/externalBundesagentur/bfa-job-posting-language/bfa-job-posting-language.vue');
// prettier-ignore
const BfaJobPostingLanguageUpdate = () => import('@/entities/externalBundesagentur/bfa-job-posting-language/bfa-job-posting-language-update.vue');
// prettier-ignore
const BfaJobPostingLanguageDetails = () => import('@/entities/externalBundesagentur/bfa-job-posting-language/bfa-job-posting-language-details.vue');
// prettier-ignore
const BfaJobPostingLicense = () => import('@/entities/externalBundesagentur/bfa-job-posting-license/bfa-job-posting-license.vue');
// prettier-ignore
const BfaJobPostingLicenseUpdate = () => import('@/entities/externalBundesagentur/bfa-job-posting-license/bfa-job-posting-license-update.vue');
// prettier-ignore
const BfaJobPostingLicenseDetails = () => import('@/entities/externalBundesagentur/bfa-job-posting-license/bfa-job-posting-license-details.vue');
// prettier-ignore
const BfaJobPostingProfessionalTraining = () => import('@/entities/externalBundesagentur/bfa-job-posting-professional-training/bfa-job-posting-professional-training.vue');
// prettier-ignore
const BfaJobPostingProfessionalTrainingUpdate = () => import('@/entities/externalBundesagentur/bfa-job-posting-professional-training/bfa-job-posting-professional-training-update.vue');
// prettier-ignore
const BfaJobPostingProfessionalTrainingDetails = () => import('@/entities/externalBundesagentur/bfa-job-posting-professional-training/bfa-job-posting-professional-training-details.vue');
// prettier-ignore
const BfaLanguageSkillLevel = () => import('@/entities/externalBundesagentur/bfa-language-skill-level/bfa-language-skill-level.vue');
// prettier-ignore
const BfaLanguageSkillLevelUpdate = () => import('@/entities/externalBundesagentur/bfa-language-skill-level/bfa-language-skill-level-update.vue');
// prettier-ignore
const BfaLanguageSkillLevelDetails = () => import('@/entities/externalBundesagentur/bfa-language-skill-level/bfa-language-skill-level-details.vue');
// prettier-ignore
const BfaLeaderShipExperience = () => import('@/entities/externalBundesagentur/bfa-leader-ship-experience/bfa-leader-ship-experience.vue');
// prettier-ignore
const BfaLeaderShipExperienceUpdate = () => import('@/entities/externalBundesagentur/bfa-leader-ship-experience/bfa-leader-ship-experience-update.vue');
// prettier-ignore
const BfaLeaderShipExperienceDetails = () => import('@/entities/externalBundesagentur/bfa-leader-ship-experience/bfa-leader-ship-experience-details.vue');
// prettier-ignore
const BfaLocation = () => import('@/entities/externalBundesagentur/bfa-location/bfa-location.vue');
// prettier-ignore
const BfaLocationUpdate = () => import('@/entities/externalBundesagentur/bfa-location/bfa-location-update.vue');
// prettier-ignore
const BfaLocationDetails = () => import('@/entities/externalBundesagentur/bfa-location/bfa-location-details.vue');
// prettier-ignore
const BfaPersonalSkill = () => import('@/entities/externalBundesagentur/bfa-personal-skill/bfa-personal-skill.vue');
// prettier-ignore
const BfaPersonalSkillUpdate = () => import('@/entities/externalBundesagentur/bfa-personal-skill/bfa-personal-skill-update.vue');
// prettier-ignore
const BfaPersonalSkillDetails = () => import('@/entities/externalBundesagentur/bfa-personal-skill/bfa-personal-skill-details.vue');
// prettier-ignore
const BfaProfessionalExperience = () => import('@/entities/externalBundesagentur/bfa-professional-experience/bfa-professional-experience.vue');
// prettier-ignore
const BfaProfessionalExperienceUpdate = () => import('@/entities/externalBundesagentur/bfa-professional-experience/bfa-professional-experience-update.vue');
// prettier-ignore
const BfaProfessionalExperienceDetails = () => import('@/entities/externalBundesagentur/bfa-professional-experience/bfa-professional-experience-details.vue');
// prettier-ignore
const BfaSubmitReport = () => import('@/entities/externalBundesagentur/bfa-submit-report/bfa-submit-report.vue');
// prettier-ignore
const BfaSubmitReportUpdate = () => import('@/entities/externalBundesagentur/bfa-submit-report/bfa-submit-report-update.vue');
// prettier-ignore
const BfaSubmitReportDetails = () => import('@/entities/externalBundesagentur/bfa-submit-report/bfa-submit-report-details.vue');
// prettier-ignore
const BfaTravelRequired = () => import('@/entities/externalBundesagentur/bfa-travel-required/bfa-travel-required.vue');
// prettier-ignore
const BfaTravelRequiredUpdate = () => import('@/entities/externalBundesagentur/bfa-travel-required/bfa-travel-required-update.vue');
// prettier-ignore
const BfaTravelRequiredDetails = () => import('@/entities/externalBundesagentur/bfa-travel-required/bfa-travel-required-details.vue');
// prettier-ignore
const BfaWorkingPlan = () => import('@/entities/externalBundesagentur/bfa-working-plan/bfa-working-plan.vue');
// prettier-ignore
const BfaWorkingPlanUpdate = () => import('@/entities/externalBundesagentur/bfa-working-plan/bfa-working-plan-update.vue');
// prettier-ignore
const BfaWorkingPlanDetails = () => import('@/entities/externalBundesagentur/bfa-working-plan/bfa-working-plan-details.vue');
// prettier-ignore
const BfaOfferType = () => import('@/entities/externalBundesagentur/bfa-offer-type/bfa-offer-type.vue');
// prettier-ignore
const BfaOfferTypeUpdate = () => import('@/entities/externalBundesagentur/bfa-offer-type/bfa-offer-type-update.vue');
// prettier-ignore
const BfaOfferTypeDetails = () => import('@/entities/externalBundesagentur/bfa-offer-type/bfa-offer-type-details.vue');
// prettier-ignore
const BfaHardSkillLevel = () => import('@/entities/externalBundesagentur/bfa-hard-skill-level/bfa-hard-skill-level.vue');
// prettier-ignore
const BfaHardSkillLevelUpdate = () => import('@/entities/externalBundesagentur/bfa-hard-skill-level/bfa-hard-skill-level-update.vue');
// prettier-ignore
const BfaHardSkillLevelDetails = () => import('@/entities/externalBundesagentur/bfa-hard-skill-level/bfa-hard-skill-level-details.vue');
// prettier-ignore
const BfaLicenseSkillLevel = () => import('@/entities/externalBundesagentur/bfa-license-skill-level/bfa-license-skill-level.vue');
// prettier-ignore
const BfaLicenseSkillLevelUpdate = () => import('@/entities/externalBundesagentur/bfa-license-skill-level/bfa-license-skill-level-update.vue');
// prettier-ignore
const BfaLicenseSkillLevelDetails = () => import('@/entities/externalBundesagentur/bfa-license-skill-level/bfa-license-skill-level-details.vue');
// prettier-ignore
const DisplayLanguage = () => import('@/entities/partnerService/display-language/display-language.vue');
// prettier-ignore
const DisplayLanguageUpdate = () => import('@/entities/partnerService/display-language/display-language-update.vue');
// prettier-ignore
const DisplayLanguageDetails = () => import('@/entities/partnerService/display-language/display-language-details.vue');
// prettier-ignore
const Zimbra7PartnerInfo = () => import('@/entities/externalZimbra7Connector/zimbra-7-partner-info/zimbra-7-partner-info.vue');
// prettier-ignore
const Zimbra7PartnerInfoUpdate = () => import('@/entities/externalZimbra7Connector/zimbra-7-partner-info/zimbra-7-partner-info-update.vue');
// prettier-ignore
const Zimbra7PartnerInfoDetails = () => import('@/entities/externalZimbra7Connector/zimbra-7-partner-info/zimbra-7-partner-info-details.vue');
// prettier-ignore
const Zimbra7PartnerEmployee = () => import('@/entities/externalZimbra7Connector/zimbra-7-partner-employee/zimbra-7-partner-employee.vue');
// prettier-ignore
const Zimbra7PartnerEmployeeUpdate = () => import('@/entities/externalZimbra7Connector/zimbra-7-partner-employee/zimbra-7-partner-employee-update.vue');
// prettier-ignore
const Zimbra7PartnerEmployeeDetails = () => import('@/entities/externalZimbra7Connector/zimbra-7-partner-employee/zimbra-7-partner-employee-details.vue');
// prettier-ignore
const Zimbra7ApplicationSettings = () => import('@/entities/externalZimbra7Connector/zimbra-7-application-settings/zimbra-7-application-settings.vue');
// prettier-ignore
const Zimbra7ApplicationSettingsUpdate = () => import('@/entities/externalZimbra7Connector/zimbra-7-application-settings/zimbra-7-application-settings-update.vue');
// prettier-ignore
const Zimbra7ApplicationSettingsDetails = () => import('@/entities/externalZimbra7Connector/zimbra-7-application-settings/zimbra-7-application-settings-details.vue');
// jhipster-needle-add-entity-to-router-import - JHipster will import entities to the router here

export default {
  path: '/',
  component: Entities,
  children: [
    {
      path: 'previous-owner',
      name: 'PreviousOwner',
      component: PreviousOwner,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'previous-owner/new',
      name: 'PreviousOwnerCreate',
      component: PreviousOwnerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'previous-owner/:previousOwnerId/edit',
      name: 'PreviousOwnerEdit',
      component: PreviousOwnerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'previous-owner/:previousOwnerId/view',
      name: 'PreviousOwnerView',
      component: PreviousOwnerDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'communication-language',
      name: 'CommunicationLanguage',
      component: CommunicationLanguage,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'communication-language/new',
      name: 'CommunicationLanguageCreate',
      component: CommunicationLanguageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'communication-language/:communicationLanguageId/edit',
      name: 'CommunicationLanguageEdit',
      component: CommunicationLanguageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'communication-language/:communicationLanguageId/view',
      name: 'CommunicationLanguageView',
      component: CommunicationLanguageDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-profile-folder-counter',
      name: 'ActiveProfileFolderCounter',
      component: ActiveProfileFolderCounter,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-profile-folder-counter/new',
      name: 'ActiveProfileFolderCounterCreate',
      component: ActiveProfileFolderCounterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-profile-folder-counter/:activeProfileFolderCounterId/edit',
      name: 'ActiveProfileFolderCounterEdit',
      component: ActiveProfileFolderCounterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-profile-folder-counter/:activeProfileFolderCounterId/view',
      name: 'ActiveProfileFolderCounterView',
      component: ActiveProfileFolderCounterDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-profile-counter',
      name: 'ActiveProfileCounter',
      component: ActiveProfileCounter,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-profile-counter/new',
      name: 'ActiveProfileCounterCreate',
      component: ActiveProfileCounterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-profile-counter/:activeProfileCounterId/edit',
      name: 'ActiveProfileCounterEdit',
      component: ActiveProfileCounterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-profile-counter/:activeProfileCounterId/view',
      name: 'ActiveProfileCounterView',
      component: ActiveProfileCounterDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'contact-details',
      name: 'ContactDetails',
      component: ContactDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'contact-details/new',
      name: 'ContactDetailsCreate',
      component: ContactDetailsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'contact-details/:contactDetailsId/edit',
      name: 'ContactDetailsEdit',
      component: ContactDetailsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'contact-details/:contactDetailsId/view',
      name: 'ContactDetailsView',
      component: ContactDetailsDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-employee',
      name: 'PartnerEmployee',
      component: PartnerEmployee,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-employee/new',
      name: 'PartnerEmployeeCreate',
      component: PartnerEmployeeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-employee/:partnerEmployeeId/edit',
      name: 'PartnerEmployeeEdit',
      component: PartnerEmployeeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-employee/:partnerEmployeeId/view',
      name: 'PartnerEmployeeView',
      component: PartnerEmployeeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile-resume-section',
      name: 'ApplicantProfileResumeSection',
      component: ApplicantProfileResumeSection,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile-resume-section/new',
      name: 'ApplicantProfileResumeSectionCreate',
      component: ApplicantProfileResumeSectionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile-resume-section/:applicantProfileResumeSectionId/edit',
      name: 'ApplicantProfileResumeSectionEdit',
      component: ApplicantProfileResumeSectionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile-resume-section/:applicantProfileResumeSectionId/view',
      name: 'ApplicantProfileResumeSectionView',
      component: ApplicantProfileResumeSectionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-activity',
      name: 'CustomerActivity',
      component: CustomerActivity,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-activity/new',
      name: 'CustomerActivityCreate',
      component: CustomerActivityUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-activity/:customerActivityId/edit',
      name: 'CustomerActivityEdit',
      component: CustomerActivityUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-activity/:customerActivityId/view',
      name: 'CustomerActivityView',
      component: CustomerActivityDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-company-size',
      name: 'CustomerCompanySize',
      component: CustomerCompanySize,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-company-size/new',
      name: 'CustomerCompanySizeCreate',
      component: CustomerCompanySizeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-company-size/:customerCompanySizeId/edit',
      name: 'CustomerCompanySizeEdit',
      component: CustomerCompanySizeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-company-size/:customerCompanySizeId/view',
      name: 'CustomerCompanySizeView',
      component: CustomerCompanySizeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-company',
      name: 'CustomerCompany',
      component: CustomerCompany,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-company/new',
      name: 'CustomerCompanyCreate',
      component: CustomerCompanyUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-company/:customerCompanyId/edit',
      name: 'CustomerCompanyEdit',
      component: CustomerCompanyUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-company/:customerCompanyId/view',
      name: 'CustomerCompanyView',
      component: CustomerCompanyDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'referer',
      name: 'Referer',
      component: Referer,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'referer/new',
      name: 'RefererCreate',
      component: RefererUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'referer/:refererId/edit',
      name: 'RefererEdit',
      component: RefererUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'referer/:refererId/view',
      name: 'RefererView',
      component: RefererDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile-resume-section-category',
      name: 'ApplicantProfileResumeSectionCategory',
      component: ApplicantProfileResumeSectionCategory,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile-resume-section-category/new',
      name: 'ApplicantProfileResumeSectionCategoryCreate',
      component: ApplicantProfileResumeSectionCategoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile-resume-section-category/:applicantProfileResumeSectionCategoryId/edit',
      name: 'ApplicantProfileResumeSectionCategoryEdit',
      component: ApplicantProfileResumeSectionCategoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile-resume-section-category/:applicantProfileResumeSectionCategoryId/view',
      name: 'ApplicantProfileResumeSectionCategoryView',
      component: ApplicantProfileResumeSectionCategoryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-job-posting-counter',
      name: 'CustomerJobPostingCounter',
      component: CustomerJobPostingCounter,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-job-posting-counter/new',
      name: 'CustomerJobPostingCounterCreate',
      component: CustomerJobPostingCounterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-job-posting-counter/:customerJobPostingCounterId/edit',
      name: 'CustomerJobPostingCounterEdit',
      component: CustomerJobPostingCounterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-job-posting-counter/:customerJobPostingCounterId/view',
      name: 'CustomerJobPostingCounterView',
      component: CustomerJobPostingCounterDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template',
      name: 'TextTemplate',
      component: TextTemplate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template/new',
      name: 'TextTemplateCreate',
      component: TextTemplateUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template/:textTemplateId/edit',
      name: 'TextTemplateEdit',
      component: TextTemplateUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template/:textTemplateId/view',
      name: 'TextTemplateView',
      component: TextTemplateDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template-variable',
      name: 'TextTemplateVariable',
      component: TextTemplateVariable,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template-variable/new',
      name: 'TextTemplateVariableCreate',
      component: TextTemplateVariableUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template-variable/:textTemplateVariableId/edit',
      name: 'TextTemplateVariableEdit',
      component: TextTemplateVariableUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template-variable/:textTemplateVariableId/view',
      name: 'TextTemplateVariableView',
      component: TextTemplateVariableDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-profile',
      name: 'ActiveProfile',
      component: ActiveProfile,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-profile/new',
      name: 'ActiveProfileCreate',
      component: ActiveProfileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-profile/:activeProfileId/edit',
      name: 'ActiveProfileEdit',
      component: ActiveProfileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-profile/:activeProfileId/view',
      name: 'ActiveProfileView',
      component: ActiveProfileDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile-resume-section-area',
      name: 'ApplicantProfileResumeSectionArea',
      component: ApplicantProfileResumeSectionArea,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile-resume-section-area/new',
      name: 'ApplicantProfileResumeSectionAreaCreate',
      component: ApplicantProfileResumeSectionAreaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile-resume-section-area/:applicantProfileResumeSectionAreaId/edit',
      name: 'ApplicantProfileResumeSectionAreaEdit',
      component: ApplicantProfileResumeSectionAreaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile-resume-section-area/:applicantProfileResumeSectionAreaId/view',
      name: 'ApplicantProfileResumeSectionAreaView',
      component: ApplicantProfileResumeSectionAreaDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner',
      name: 'Partner',
      component: Partner,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner/new',
      name: 'PartnerCreate',
      component: PartnerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner/:partnerId/edit',
      name: 'PartnerEdit',
      component: PartnerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner/:partnerId/view',
      name: 'PartnerView',
      component: PartnerDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'activity-type',
      name: 'ActivityType',
      component: ActivityType,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'activity-type/new',
      name: 'ActivityTypeCreate',
      component: ActivityTypeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'activity-type/:activityTypeId/edit',
      name: 'ActivityTypeEdit',
      component: ActivityTypeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'activity-type/:activityTypeId/view',
      name: 'ActivityTypeView',
      component: ActivityTypeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-reference',
      name: 'CustomerReference',
      component: CustomerReference,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-reference/new',
      name: 'CustomerReferenceCreate',
      component: CustomerReferenceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-reference/:customerReferenceId/edit',
      name: 'CustomerReferenceEdit',
      component: CustomerReferenceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-reference/:customerReferenceId/view',
      name: 'CustomerReferenceView',
      component: CustomerReferenceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'marital-status',
      name: 'MaritalStatus',
      component: MaritalStatus,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'marital-status/new',
      name: 'MaritalStatusCreate',
      component: MaritalStatusUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'marital-status/:maritalStatusId/edit',
      name: 'MaritalStatusEdit',
      component: MaritalStatusUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'marital-status/:maritalStatusId/view',
      name: 'MaritalStatusView',
      component: MaritalStatusDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-job-posting-portal',
      name: 'CustomerJobPostingPortal',
      component: CustomerJobPostingPortal,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-job-posting-portal/new',
      name: 'CustomerJobPostingPortalCreate',
      component: CustomerJobPostingPortalUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-job-posting-portal/:customerJobPostingPortalId/edit',
      name: 'CustomerJobPostingPortalEdit',
      component: CustomerJobPostingPortalUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-job-posting-portal/:customerJobPostingPortalId/view',
      name: 'CustomerJobPostingPortalView',
      component: CustomerJobPostingPortalDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission',
      name: 'Permission',
      component: Permission,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission/new',
      name: 'PermissionCreate',
      component: PermissionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission/:permissionId/edit',
      name: 'PermissionEdit',
      component: PermissionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'permission/:permissionId/view',
      name: 'PermissionView',
      component: PermissionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'placement-type',
      name: 'PlacementType',
      component: PlacementType,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'placement-type/new',
      name: 'PlacementTypeCreate',
      component: PlacementTypeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'placement-type/:placementTypeId/edit',
      name: 'PlacementTypeEdit',
      component: PlacementTypeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'placement-type/:placementTypeId/view',
      name: 'PlacementTypeView',
      component: PlacementTypeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'note',
      name: 'Note',
      component: Note,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'note/new',
      name: 'NoteCreate',
      component: NoteUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'note/:noteId/edit',
      name: 'NoteEdit',
      component: NoteUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'note/:noteId/view',
      name: 'NoteView',
      component: NoteDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-status',
      name: 'ApplicantStatus',
      component: ApplicantStatus,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-status/new',
      name: 'ApplicantStatusCreate',
      component: ApplicantStatusUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-status/:applicantStatusId/edit',
      name: 'ApplicantStatusEdit',
      component: ApplicantStatusUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-status/:applicantStatusId/view',
      name: 'ApplicantStatusView',
      component: ApplicantStatusDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'streaming-ip-blacklist-entry',
      name: 'StreamingIpBlacklistEntry',
      component: StreamingIpBlacklistEntry,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'streaming-ip-blacklist-entry/new',
      name: 'StreamingIpBlacklistEntryCreate',
      component: StreamingIpBlacklistEntryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'streaming-ip-blacklist-entry/:streamingIpBlacklistEntryId/edit',
      name: 'StreamingIpBlacklistEntryEdit',
      component: StreamingIpBlacklistEntryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'streaming-ip-blacklist-entry/:streamingIpBlacklistEntryId/view',
      name: 'StreamingIpBlacklistEntryView',
      component: StreamingIpBlacklistEntryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-language',
      name: 'ApplicantLanguage',
      component: ApplicantLanguage,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-language/new',
      name: 'ApplicantLanguageCreate',
      component: ApplicantLanguageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-language/:applicantLanguageId/edit',
      name: 'ApplicantLanguageEdit',
      component: ApplicantLanguageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-language/:applicantLanguageId/view',
      name: 'ApplicantLanguageView',
      component: ApplicantLanguageDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile',
      name: 'ApplicantProfile',
      component: ApplicantProfile,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile/new',
      name: 'ApplicantProfileCreate',
      component: ApplicantProfileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile/:applicantProfileId/edit',
      name: 'ApplicantProfileEdit',
      component: ApplicantProfileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile/:applicantProfileId/view',
      name: 'ApplicantProfileView',
      component: ApplicantProfileDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-profile-folder',
      name: 'ActiveProfileFolder',
      component: ActiveProfileFolder,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-profile-folder/new',
      name: 'ActiveProfileFolderCreate',
      component: ActiveProfileFolderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-profile-folder/:activeProfileFolderId/edit',
      name: 'ActiveProfileFolderEdit',
      component: ActiveProfileFolderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-profile-folder/:activeProfileFolderId/view',
      name: 'ActiveProfileFolderView',
      component: ActiveProfileFolderDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'profession',
      name: 'Profession',
      component: Profession,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'profession/new',
      name: 'ProfessionCreate',
      component: ProfessionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'profession/:professionId/edit',
      name: 'ProfessionEdit',
      component: ProfessionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'profession/:professionId/view',
      name: 'ProfessionView',
      component: ProfessionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'mailing-blacklist-entry',
      name: 'MailingBlacklistEntry',
      component: MailingBlacklistEntry,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'mailing-blacklist-entry/new',
      name: 'MailingBlacklistEntryCreate',
      component: MailingBlacklistEntryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'mailing-blacklist-entry/:mailingBlacklistEntryId/edit',
      name: 'MailingBlacklistEntryEdit',
      component: MailingBlacklistEntryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'mailing-blacklist-entry/:mailingBlacklistEntryId/view',
      name: 'MailingBlacklistEntryView',
      component: MailingBlacklistEntryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template-category',
      name: 'TextTemplateCategory',
      component: TextTemplateCategory,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template-category/new',
      name: 'TextTemplateCategoryCreate',
      component: TextTemplateCategoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template-category/:textTemplateCategoryId/edit',
      name: 'TextTemplateCategoryEdit',
      component: TextTemplateCategoryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template-category/:textTemplateCategoryId/view',
      name: 'TextTemplateCategoryView',
      component: TextTemplateCategoryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template-area',
      name: 'TextTemplateArea',
      component: TextTemplateArea,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template-area/new',
      name: 'TextTemplateAreaCreate',
      component: TextTemplateAreaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template-area/:textTemplateAreaId/edit',
      name: 'TextTemplateAreaEdit',
      component: TextTemplateAreaUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template-area/:textTemplateAreaId/view',
      name: 'TextTemplateAreaView',
      component: TextTemplateAreaDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-job-posting-counter',
      name: 'ActiveJobPostingCounter',
      component: ActiveJobPostingCounter,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-job-posting-counter/new',
      name: 'ActiveJobPostingCounterCreate',
      component: ActiveJobPostingCounterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-job-posting-counter/:activeJobPostingCounterId/edit',
      name: 'ActiveJobPostingCounterEdit',
      component: ActiveJobPostingCounterUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-job-posting-counter/:activeJobPostingCounterId/view',
      name: 'ActiveJobPostingCounterView',
      component: ActiveJobPostingCounterDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'application-settings',
      name: 'ApplicationSettings',
      component: ApplicationSettings,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'application-settings/new',
      name: 'ApplicationSettingsCreate',
      component: ApplicationSettingsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'application-settings/:applicationSettingsId/edit',
      name: 'ApplicationSettingsEdit',
      component: ApplicationSettingsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'application-settings/:applicationSettingsId/view',
      name: 'ApplicationSettingsView',
      component: ApplicationSettingsDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template-variable-value-path',
      name: 'TextTemplateVariableValuePath',
      component: TextTemplateVariableValuePath,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template-variable-value-path/new',
      name: 'TextTemplateVariableValuePathCreate',
      component: TextTemplateVariableValuePathUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template-variable-value-path/:textTemplateVariableValuePathId/edit',
      name: 'TextTemplateVariableValuePathEdit',
      component: TextTemplateVariableValuePathUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'text-template-variable-value-path/:textTemplateVariableValuePathId/view',
      name: 'TextTemplateVariableValuePathView',
      component: TextTemplateVariableValuePathDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-activity',
      name: 'ApplicantActivity',
      component: ApplicantActivity,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-activity/new',
      name: 'ApplicantActivityCreate',
      component: ApplicantActivityUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-activity/:applicantActivityId/edit',
      name: 'ApplicantActivityEdit',
      component: ApplicantActivityUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-activity/:applicantActivityId/view',
      name: 'ApplicantActivityView',
      component: ApplicantActivityDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'external-job-posting-service-providers',
      name: 'ExternalJobPostingServiceProviders',
      component: ExternalJobPostingServiceProviders,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'external-job-posting-service-providers/new',
      name: 'ExternalJobPostingServiceProvidersCreate',
      component: ExternalJobPostingServiceProvidersUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'external-job-posting-service-providers/:externalJobPostingServiceProvidersId/edit',
      name: 'ExternalJobPostingServiceProvidersEdit',
      component: ExternalJobPostingServiceProvidersUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'external-job-posting-service-providers/:externalJobPostingServiceProvidersId/view',
      name: 'ExternalJobPostingServiceProvidersView',
      component: ExternalJobPostingServiceProvidersDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-type',
      name: 'CustomerType',
      component: CustomerType,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-type/new',
      name: 'CustomerTypeCreate',
      component: CustomerTypeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-type/:customerTypeId/edit',
      name: 'CustomerTypeEdit',
      component: CustomerTypeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-type/:customerTypeId/view',
      name: 'CustomerTypeView',
      component: CustomerTypeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tag',
      name: 'Tag',
      component: Tag,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tag/new',
      name: 'TagCreate',
      component: TagUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tag/:tagId/edit',
      name: 'TagEdit',
      component: TagUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'tag/:tagId/view',
      name: 'TagView',
      component: TagDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'two-factor-auth-whitelist-entry',
      name: 'TwoFactorAuthWhitelistEntry',
      component: TwoFactorAuthWhitelistEntry,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'two-factor-auth-whitelist-entry/new',
      name: 'TwoFactorAuthWhitelistEntryCreate',
      component: TwoFactorAuthWhitelistEntryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'two-factor-auth-whitelist-entry/:twoFactorAuthWhitelistEntryId/edit',
      name: 'TwoFactorAuthWhitelistEntryEdit',
      component: TwoFactorAuthWhitelistEntryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'two-factor-auth-whitelist-entry/:twoFactorAuthWhitelistEntryId/view',
      name: 'TwoFactorAuthWhitelistEntryView',
      component: TwoFactorAuthWhitelistEntryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-priority',
      name: 'CustomerPriority',
      component: CustomerPriority,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-priority/new',
      name: 'CustomerPriorityCreate',
      component: CustomerPriorityUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-priority/:customerPriorityId/edit',
      name: 'CustomerPriorityEdit',
      component: CustomerPriorityUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-priority/:customerPriorityId/view',
      name: 'CustomerPriorityView',
      component: CustomerPriorityDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-job-posting',
      name: 'ActiveJobPosting',
      component: ActiveJobPosting,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-job-posting/new',
      name: 'ActiveJobPostingCreate',
      component: ActiveJobPostingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-job-posting/:activeJobPostingId/edit',
      name: 'ActiveJobPostingEdit',
      component: ActiveJobPostingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'active-job-posting/:activeJobPostingId/view',
      name: 'ActiveJobPostingView',
      component: ActiveJobPostingDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-contact',
      name: 'CustomerContact',
      component: CustomerContact,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-contact/new',
      name: 'CustomerContactCreate',
      component: CustomerContactUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-contact/:customerContactId/edit',
      name: 'CustomerContactEdit',
      component: CustomerContactUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-contact/:customerContactId/view',
      name: 'CustomerContactView',
      component: CustomerContactDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile-type',
      name: 'ApplicantProfileType',
      component: ApplicantProfileType,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile-type/new',
      name: 'ApplicantProfileTypeCreate',
      component: ApplicantProfileTypeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile-type/:applicantProfileTypeId/edit',
      name: 'ApplicantProfileTypeEdit',
      component: ApplicantProfileTypeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-profile-type/:applicantProfileTypeId/view',
      name: 'ApplicantProfileTypeView',
      component: ApplicantProfileTypeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'region',
      name: 'Region',
      component: Region,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'region/new',
      name: 'RegionCreate',
      component: RegionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'region/:regionId/edit',
      name: 'RegionEdit',
      component: RegionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'region/:regionId/view',
      name: 'RegionView',
      component: RegionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant',
      name: 'Applicant',
      component: Applicant,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant/new',
      name: 'ApplicantCreate',
      component: ApplicantUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant/:applicantId/edit',
      name: 'ApplicantEdit',
      component: ApplicantUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant/:applicantId/view',
      name: 'ApplicantView',
      component: ApplicantDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-job-posting',
      name: 'CustomerJobPosting',
      component: CustomerJobPosting,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-job-posting/new',
      name: 'CustomerJobPostingCreate',
      component: CustomerJobPostingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-job-posting/:customerJobPostingId/edit',
      name: 'CustomerJobPostingEdit',
      component: CustomerJobPostingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-job-posting/:customerJobPostingId/view',
      name: 'CustomerJobPostingView',
      component: CustomerJobPostingDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'nationality',
      name: 'Nationality',
      component: Nationality,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'nationality/new',
      name: 'NationalityCreate',
      component: NationalityUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'nationality/:nationalityId/edit',
      name: 'NationalityEdit',
      component: NationalityUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'nationality/:nationalityId/view',
      name: 'NationalityView',
      component: NationalityDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'country',
      name: 'Country',
      component: Country,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'country/new',
      name: 'CountryCreate',
      component: CountryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'country/:countryId/edit',
      name: 'CountryEdit',
      component: CountryUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'country/:countryId/view',
      name: 'CountryView',
      component: CountryDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-period-of-notice',
      name: 'ApplicantPeriodOfNotice',
      component: ApplicantPeriodOfNotice,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-period-of-notice/new',
      name: 'ApplicantPeriodOfNoticeCreate',
      component: ApplicantPeriodOfNoticeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-period-of-notice/:applicantPeriodOfNoticeId/edit',
      name: 'ApplicantPeriodOfNoticeEdit',
      component: ApplicantPeriodOfNoticeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-period-of-notice/:applicantPeriodOfNoticeId/view',
      name: 'ApplicantPeriodOfNoticeView',
      component: ApplicantPeriodOfNoticeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'application-type',
      name: 'ApplicationType',
      component: ApplicationType,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'application-type/new',
      name: 'ApplicationTypeCreate',
      component: ApplicationTypeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'application-type/:applicationTypeId/edit',
      name: 'ApplicationTypeEdit',
      component: ApplicationTypeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'application-type/:applicationTypeId/view',
      name: 'ApplicationTypeView',
      component: ApplicationTypeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'address-details',
      name: 'AddressDetails',
      component: AddressDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'address-details/new',
      name: 'AddressDetailsCreate',
      component: AddressDetailsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'address-details/:addressDetailsId/edit',
      name: 'AddressDetailsEdit',
      component: AddressDetailsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'address-details/:addressDetailsId/view',
      name: 'AddressDetailsView',
      component: AddressDetailsDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-employee-account-settings',
      name: 'PartnerEmployeeAccountSettings',
      component: PartnerEmployeeAccountSettings,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-employee-account-settings/new',
      name: 'PartnerEmployeeAccountSettingsCreate',
      component: PartnerEmployeeAccountSettingsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-employee-account-settings/:partnerEmployeeAccountSettingsId/edit',
      name: 'PartnerEmployeeAccountSettingsEdit',
      component: PartnerEmployeeAccountSettingsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-employee-account-settings/:partnerEmployeeAccountSettingsId/view',
      name: 'PartnerEmployeeAccountSettingsView',
      component: PartnerEmployeeAccountSettingsDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-file',
      name: 'ApplicantFile',
      component: ApplicantFile,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-file/new',
      name: 'ApplicantFileCreate',
      component: ApplicantFileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-file/:applicantFileId/edit',
      name: 'ApplicantFileEdit',
      component: ApplicantFileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'applicant-file/:applicantFileId/view',
      name: 'ApplicantFileView',
      component: ApplicantFileDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-file',
      name: 'CustomerFile',
      component: CustomerFile,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-file/new',
      name: 'CustomerFileCreate',
      component: CustomerFileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-file/:customerFileId/edit',
      name: 'CustomerFileEdit',
      component: CustomerFileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-file/:customerFileId/view',
      name: 'CustomerFileView',
      component: CustomerFileDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-file',
      name: 'PartnerFile',
      component: PartnerFile,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-file/new',
      name: 'PartnerFileCreate',
      component: PartnerFileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-file/:partnerFileId/edit',
      name: 'PartnerFileEdit',
      component: PartnerFileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-file/:partnerFileId/view',
      name: 'PartnerFileView',
      component: PartnerFileDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-employee-file',
      name: 'PartnerEmployeeFile',
      component: PartnerEmployeeFile,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-employee-file/new',
      name: 'PartnerEmployeeFileCreate',
      component: PartnerEmployeeFileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-employee-file/:partnerEmployeeFileId/edit',
      name: 'PartnerEmployeeFileEdit',
      component: PartnerEmployeeFileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'partner-employee-file/:partnerEmployeeFileId/view',
      name: 'PartnerEmployeeFileView',
      component: PartnerEmployeeFileDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'profession-file',
      name: 'ProfessionFile',
      component: ProfessionFile,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'profession-file/new',
      name: 'ProfessionFileCreate',
      component: ProfessionFileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'profession-file/:professionFileId/edit',
      name: 'ProfessionFileEdit',
      component: ProfessionFileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'profession-file/:professionFileId/view',
      name: 'ProfessionFileView',
      component: ProfessionFileDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'language',
      name: 'Language',
      component: Language,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'language/new',
      name: 'LanguageCreate',
      component: LanguageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'language/:languageId/edit',
      name: 'LanguageEdit',
      component: LanguageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'language/:languageId/view',
      name: 'LanguageView',
      component: LanguageDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'language-skill',
      name: 'LanguageSkill',
      component: LanguageSkill,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'language-skill/new',
      name: 'LanguageSkillCreate',
      component: LanguageSkillUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'language-skill/:languageSkillId/edit',
      name: 'LanguageSkillEdit',
      component: LanguageSkillUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'language-skill/:languageSkillId/view',
      name: 'LanguageSkillView',
      component: LanguageSkillDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'activity-file',
      name: 'ActivityFile',
      component: ActivityFile,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'activity-file/new',
      name: 'ActivityFileCreate',
      component: ActivityFileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'activity-file/:activityFileId/edit',
      name: 'ActivityFileEdit',
      component: ActivityFileUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'activity-file/:activityFileId/view',
      name: 'ActivityFileView',
      component: ActivityFileDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-order',
      name: 'CustomerOrder',
      component: CustomerOrder,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-order/new',
      name: 'CustomerOrderCreate',
      component: CustomerOrderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-order/:customerOrderId/edit',
      name: 'CustomerOrderEdit',
      component: CustomerOrderUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-order/:customerOrderId/view',
      name: 'CustomerOrderView',
      component: CustomerOrderDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'driving-license',
      name: 'DrivingLicense',
      component: DrivingLicense,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'driving-license/new',
      name: 'DrivingLicenseCreate',
      component: DrivingLicenseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'driving-license/:drivingLicenseId/edit',
      name: 'DrivingLicenseEdit',
      component: DrivingLicenseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'driving-license/:drivingLicenseId/view',
      name: 'DrivingLicenseView',
      component: DrivingLicenseDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-order-language',
      name: 'CustomerOrderLanguage',
      component: CustomerOrderLanguage,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-order-language/new',
      name: 'CustomerOrderLanguageCreate',
      component: CustomerOrderLanguageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-order-language/:customerOrderLanguageId/edit',
      name: 'CustomerOrderLanguageEdit',
      component: CustomerOrderLanguageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'customer-order-language/:customerOrderLanguageId/view',
      name: 'CustomerOrderLanguageView',
      component: CustomerOrderLanguageDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'matching-preset',
      name: 'MatchingPreset',
      component: MatchingPreset,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'matching-preset/new',
      name: 'MatchingPresetCreate',
      component: MatchingPresetUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'matching-preset/:matchingPresetId/edit',
      name: 'MatchingPresetEdit',
      component: MatchingPresetUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'matching-preset/:matchingPresetId/view',
      name: 'MatchingPresetView',
      component: MatchingPresetDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'matching-preset-applicant-language',
      name: 'MatchingPresetApplicantLanguage',
      component: MatchingPresetApplicantLanguage,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'matching-preset-applicant-language/new',
      name: 'MatchingPresetApplicantLanguageCreate',
      component: MatchingPresetApplicantLanguageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'matching-preset-applicant-language/:matchingPresetApplicantLanguageId/edit',
      name: 'MatchingPresetApplicantLanguageEdit',
      component: MatchingPresetApplicantLanguageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'matching-preset-applicant-language/:matchingPresetApplicantLanguageId/view',
      name: 'MatchingPresetApplicantLanguageView',
      component: MatchingPresetApplicantLanguageDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-api-settings',
      name: 'BfaApiSettings',
      component: BfaApiSettings,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-api-settings/new',
      name: 'BfaApiSettingsCreate',
      component: BfaApiSettingsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-api-settings/:bfaApiSettingsId/edit',
      name: 'BfaApiSettingsEdit',
      component: BfaApiSettingsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-api-settings/:bfaApiSettingsId/view',
      name: 'BfaApiSettingsView',
      component: BfaApiSettingsDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-competence',
      name: 'BfaCompetence',
      component: BfaCompetence,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-competence/new',
      name: 'BfaCompetenceCreate',
      component: BfaCompetenceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-competence/:bfaCompetenceId/edit',
      name: 'BfaCompetenceEdit',
      component: BfaCompetenceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-competence/:bfaCompetenceId/view',
      name: 'BfaCompetenceView',
      component: BfaCompetenceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-coop-partner',
      name: 'BfaCoopPartner',
      component: BfaCoopPartner,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-coop-partner/new',
      name: 'BfaCoopPartnerCreate',
      component: BfaCoopPartnerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-coop-partner/:bfaCoopPartnerId/edit',
      name: 'BfaCoopPartnerEdit',
      component: BfaCoopPartnerUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-coop-partner/:bfaCoopPartnerId/view',
      name: 'BfaCoopPartnerView',
      component: BfaCoopPartnerDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-kind-of-application',
      name: 'BfaKindOfApplication',
      component: BfaKindOfApplication,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-kind-of-application/new',
      name: 'BfaKindOfApplicationCreate',
      component: BfaKindOfApplicationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-kind-of-application/:bfaKindOfApplicationId/edit',
      name: 'BfaKindOfApplicationEdit',
      component: BfaKindOfApplicationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-kind-of-application/:bfaKindOfApplicationId/view',
      name: 'BfaKindOfApplicationView',
      component: BfaKindOfApplicationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-leader-ship',
      name: 'BfaLeaderShip',
      component: BfaLeaderShip,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-leader-ship/new',
      name: 'BfaLeaderShipCreate',
      component: BfaLeaderShipUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-leader-ship/:bfaLeaderShipId/edit',
      name: 'BfaLeaderShipEdit',
      component: BfaLeaderShipUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-leader-ship/:bfaLeaderShipId/view',
      name: 'BfaLeaderShipView',
      component: BfaLeaderShipDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-partner-info',
      name: 'BfaPartnerInfo',
      component: BfaPartnerInfo,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-partner-info/new',
      name: 'BfaPartnerInfoCreate',
      component: BfaPartnerInfoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-partner-info/:bfaPartnerInfoId/edit',
      name: 'BfaPartnerInfoEdit',
      component: BfaPartnerInfoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-partner-info/:bfaPartnerInfoId/view',
      name: 'BfaPartnerInfoView',
      component: BfaPartnerInfoDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-region',
      name: 'BfaRegion',
      component: BfaRegion,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-region/new',
      name: 'BfaRegionCreate',
      component: BfaRegionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-region/:bfaRegionId/edit',
      name: 'BfaRegionEdit',
      component: BfaRegionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-region/:bfaRegionId/view',
      name: 'BfaRegionView',
      component: BfaRegionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-salutation',
      name: 'BfaSalutation',
      component: BfaSalutation,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-salutation/new',
      name: 'BfaSalutationCreate',
      component: BfaSalutationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-salutation/:bfaSalutationId/edit',
      name: 'BfaSalutationEdit',
      component: BfaSalutationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-salutation/:bfaSalutationId/view',
      name: 'BfaSalutationView',
      component: BfaSalutationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-workplace',
      name: 'BfaWorkplace',
      component: BfaWorkplace,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-workplace/new',
      name: 'BfaWorkplaceCreate',
      component: BfaWorkplaceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-workplace/:bfaWorkplaceId/edit',
      name: 'BfaWorkplaceEdit',
      component: BfaWorkplaceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-workplace/:bfaWorkplaceId/view',
      name: 'BfaWorkplaceView',
      component: BfaWorkplaceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting',
      name: 'BfaJobPosting',
      component: BfaJobPosting,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting/new',
      name: 'BfaJobPostingCreate',
      component: BfaJobPostingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting/:bfaJobPostingId/edit',
      name: 'BfaJobPostingEdit',
      component: BfaJobPostingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting/:bfaJobPostingId/view',
      name: 'BfaJobPostingView',
      component: BfaJobPostingDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-vam-profession',
      name: 'BfaVamProfession',
      component: BfaVamProfession,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-vam-profession/new',
      name: 'BfaVamProfessionCreate',
      component: BfaVamProfessionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-vam-profession/:bfaVamProfessionId/edit',
      name: 'BfaVamProfessionEdit',
      component: BfaVamProfessionUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-vam-profession/:bfaVamProfessionId/view',
      name: 'BfaVamProfessionView',
      component: BfaVamProfessionDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-authority',
      name: 'BfaAuthority',
      component: BfaAuthority,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-authority/new',
      name: 'BfaAuthorityCreate',
      component: BfaAuthorityUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-authority/:bfaAuthorityId/edit',
      name: 'BfaAuthorityEdit',
      component: BfaAuthorityUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-authority/:bfaAuthorityId/view',
      name: 'BfaAuthorityView',
      component: BfaAuthorityDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-budget-responsibility',
      name: 'BfaBudgetResponsibility',
      component: BfaBudgetResponsibility,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-budget-responsibility/new',
      name: 'BfaBudgetResponsibilityCreate',
      component: BfaBudgetResponsibilityUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-budget-responsibility/:bfaBudgetResponsibilityId/edit',
      name: 'BfaBudgetResponsibilityEdit',
      component: BfaBudgetResponsibilityUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-budget-responsibility/:bfaBudgetResponsibilityId/view',
      name: 'BfaBudgetResponsibilityView',
      component: BfaBudgetResponsibilityDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-degree',
      name: 'BfaDegree',
      component: BfaDegree,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-degree/new',
      name: 'BfaDegreeCreate',
      component: BfaDegreeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-degree/:bfaDegreeId/edit',
      name: 'BfaDegreeEdit',
      component: BfaDegreeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-degree/:bfaDegreeId/view',
      name: 'BfaDegreeView',
      component: BfaDegreeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-employee-responsibility',
      name: 'BfaEmployeeResponsibility',
      component: BfaEmployeeResponsibility,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-employee-responsibility/new',
      name: 'BfaEmployeeResponsibilityCreate',
      component: BfaEmployeeResponsibilityUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-employee-responsibility/:bfaEmployeeResponsibilityId/edit',
      name: 'BfaEmployeeResponsibilityEdit',
      component: BfaEmployeeResponsibilityUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-employee-responsibility/:bfaEmployeeResponsibilityId/view',
      name: 'BfaEmployeeResponsibilityView',
      component: BfaEmployeeResponsibilityDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-error-code-mapping',
      name: 'BfaErrorCodeMapping',
      component: BfaErrorCodeMapping,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-error-code-mapping/new',
      name: 'BfaErrorCodeMappingCreate',
      component: BfaErrorCodeMappingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-error-code-mapping/:bfaErrorCodeMappingId/edit',
      name: 'BfaErrorCodeMappingEdit',
      component: BfaErrorCodeMappingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-error-code-mapping/:bfaErrorCodeMappingId/view',
      name: 'BfaErrorCodeMappingView',
      component: BfaErrorCodeMappingDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-handicap',
      name: 'BfaHandicap',
      component: BfaHandicap,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-handicap/new',
      name: 'BfaHandicapCreate',
      component: BfaHandicapUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-handicap/:bfaHandicapId/edit',
      name: 'BfaHandicapEdit',
      component: BfaHandicapUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-handicap/:bfaHandicapId/view',
      name: 'BfaHandicapView',
      component: BfaHandicapDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-driving-license',
      name: 'BfaJobPostingDrivingLicense',
      component: BfaJobPostingDrivingLicense,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-driving-license/new',
      name: 'BfaJobPostingDrivingLicenseCreate',
      component: BfaJobPostingDrivingLicenseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-driving-license/:bfaJobPostingDrivingLicenseId/edit',
      name: 'BfaJobPostingDrivingLicenseEdit',
      component: BfaJobPostingDrivingLicenseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-driving-license/:bfaJobPostingDrivingLicenseId/view',
      name: 'BfaJobPostingDrivingLicenseView',
      component: BfaJobPostingDrivingLicenseDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-hard-skill',
      name: 'BfaJobPostingHardSkill',
      component: BfaJobPostingHardSkill,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-hard-skill/new',
      name: 'BfaJobPostingHardSkillCreate',
      component: BfaJobPostingHardSkillUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-hard-skill/:bfaJobPostingHardSkillId/edit',
      name: 'BfaJobPostingHardSkillEdit',
      component: BfaJobPostingHardSkillUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-hard-skill/:bfaJobPostingHardSkillId/view',
      name: 'BfaJobPostingHardSkillView',
      component: BfaJobPostingHardSkillDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-language',
      name: 'BfaJobPostingLanguage',
      component: BfaJobPostingLanguage,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-language/new',
      name: 'BfaJobPostingLanguageCreate',
      component: BfaJobPostingLanguageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-language/:bfaJobPostingLanguageId/edit',
      name: 'BfaJobPostingLanguageEdit',
      component: BfaJobPostingLanguageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-language/:bfaJobPostingLanguageId/view',
      name: 'BfaJobPostingLanguageView',
      component: BfaJobPostingLanguageDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-license',
      name: 'BfaJobPostingLicense',
      component: BfaJobPostingLicense,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-license/new',
      name: 'BfaJobPostingLicenseCreate',
      component: BfaJobPostingLicenseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-license/:bfaJobPostingLicenseId/edit',
      name: 'BfaJobPostingLicenseEdit',
      component: BfaJobPostingLicenseUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-license/:bfaJobPostingLicenseId/view',
      name: 'BfaJobPostingLicenseView',
      component: BfaJobPostingLicenseDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-professional-training',
      name: 'BfaJobPostingProfessionalTraining',
      component: BfaJobPostingProfessionalTraining,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-professional-training/new',
      name: 'BfaJobPostingProfessionalTrainingCreate',
      component: BfaJobPostingProfessionalTrainingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-professional-training/:bfaJobPostingProfessionalTrainingId/edit',
      name: 'BfaJobPostingProfessionalTrainingEdit',
      component: BfaJobPostingProfessionalTrainingUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-job-posting-professional-training/:bfaJobPostingProfessionalTrainingId/view',
      name: 'BfaJobPostingProfessionalTrainingView',
      component: BfaJobPostingProfessionalTrainingDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-language-skill-level',
      name: 'BfaLanguageSkillLevel',
      component: BfaLanguageSkillLevel,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-language-skill-level/new',
      name: 'BfaLanguageSkillLevelCreate',
      component: BfaLanguageSkillLevelUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-language-skill-level/:bfaLanguageSkillLevelId/edit',
      name: 'BfaLanguageSkillLevelEdit',
      component: BfaLanguageSkillLevelUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-language-skill-level/:bfaLanguageSkillLevelId/view',
      name: 'BfaLanguageSkillLevelView',
      component: BfaLanguageSkillLevelDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-leader-ship-experience',
      name: 'BfaLeaderShipExperience',
      component: BfaLeaderShipExperience,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-leader-ship-experience/new',
      name: 'BfaLeaderShipExperienceCreate',
      component: BfaLeaderShipExperienceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-leader-ship-experience/:bfaLeaderShipExperienceId/edit',
      name: 'BfaLeaderShipExperienceEdit',
      component: BfaLeaderShipExperienceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-leader-ship-experience/:bfaLeaderShipExperienceId/view',
      name: 'BfaLeaderShipExperienceView',
      component: BfaLeaderShipExperienceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-location',
      name: 'BfaLocation',
      component: BfaLocation,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-location/new',
      name: 'BfaLocationCreate',
      component: BfaLocationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-location/:bfaLocationId/edit',
      name: 'BfaLocationEdit',
      component: BfaLocationUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-location/:bfaLocationId/view',
      name: 'BfaLocationView',
      component: BfaLocationDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-personal-skill',
      name: 'BfaPersonalSkill',
      component: BfaPersonalSkill,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-personal-skill/new',
      name: 'BfaPersonalSkillCreate',
      component: BfaPersonalSkillUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-personal-skill/:bfaPersonalSkillId/edit',
      name: 'BfaPersonalSkillEdit',
      component: BfaPersonalSkillUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-personal-skill/:bfaPersonalSkillId/view',
      name: 'BfaPersonalSkillView',
      component: BfaPersonalSkillDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-professional-experience',
      name: 'BfaProfessionalExperience',
      component: BfaProfessionalExperience,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-professional-experience/new',
      name: 'BfaProfessionalExperienceCreate',
      component: BfaProfessionalExperienceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-professional-experience/:bfaProfessionalExperienceId/edit',
      name: 'BfaProfessionalExperienceEdit',
      component: BfaProfessionalExperienceUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-professional-experience/:bfaProfessionalExperienceId/view',
      name: 'BfaProfessionalExperienceView',
      component: BfaProfessionalExperienceDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-submit-report',
      name: 'BfaSubmitReport',
      component: BfaSubmitReport,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-submit-report/new',
      name: 'BfaSubmitReportCreate',
      component: BfaSubmitReportUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-submit-report/:bfaSubmitReportId/edit',
      name: 'BfaSubmitReportEdit',
      component: BfaSubmitReportUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-submit-report/:bfaSubmitReportId/view',
      name: 'BfaSubmitReportView',
      component: BfaSubmitReportDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-travel-required',
      name: 'BfaTravelRequired',
      component: BfaTravelRequired,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-travel-required/new',
      name: 'BfaTravelRequiredCreate',
      component: BfaTravelRequiredUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-travel-required/:bfaTravelRequiredId/edit',
      name: 'BfaTravelRequiredEdit',
      component: BfaTravelRequiredUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-travel-required/:bfaTravelRequiredId/view',
      name: 'BfaTravelRequiredView',
      component: BfaTravelRequiredDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-working-plan',
      name: 'BfaWorkingPlan',
      component: BfaWorkingPlan,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-working-plan/new',
      name: 'BfaWorkingPlanCreate',
      component: BfaWorkingPlanUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-working-plan/:bfaWorkingPlanId/edit',
      name: 'BfaWorkingPlanEdit',
      component: BfaWorkingPlanUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-working-plan/:bfaWorkingPlanId/view',
      name: 'BfaWorkingPlanView',
      component: BfaWorkingPlanDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-offer-type',
      name: 'BfaOfferType',
      component: BfaOfferType,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-offer-type/new',
      name: 'BfaOfferTypeCreate',
      component: BfaOfferTypeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-offer-type/:bfaOfferTypeId/edit',
      name: 'BfaOfferTypeEdit',
      component: BfaOfferTypeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-offer-type/:bfaOfferTypeId/view',
      name: 'BfaOfferTypeView',
      component: BfaOfferTypeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-hard-skill-level',
      name: 'BfaHardSkillLevel',
      component: BfaHardSkillLevel,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-hard-skill-level/new',
      name: 'BfaHardSkillLevelCreate',
      component: BfaHardSkillLevelUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-hard-skill-level/:bfaHardSkillLevelId/edit',
      name: 'BfaHardSkillLevelEdit',
      component: BfaHardSkillLevelUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-hard-skill-level/:bfaHardSkillLevelId/view',
      name: 'BfaHardSkillLevelView',
      component: BfaHardSkillLevelDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-license-skill-level',
      name: 'BfaLicenseSkillLevel',
      component: BfaLicenseSkillLevel,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-license-skill-level/new',
      name: 'BfaLicenseSkillLevelCreate',
      component: BfaLicenseSkillLevelUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-license-skill-level/:bfaLicenseSkillLevelId/edit',
      name: 'BfaLicenseSkillLevelEdit',
      component: BfaLicenseSkillLevelUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'bfa-license-skill-level/:bfaLicenseSkillLevelId/view',
      name: 'BfaLicenseSkillLevelView',
      component: BfaLicenseSkillLevelDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'display-language',
      name: 'DisplayLanguage',
      component: DisplayLanguage,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'display-language/new',
      name: 'DisplayLanguageCreate',
      component: DisplayLanguageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'display-language/:displayLanguageId/edit',
      name: 'DisplayLanguageEdit',
      component: DisplayLanguageUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'display-language/:displayLanguageId/view',
      name: 'DisplayLanguageView',
      component: DisplayLanguageDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'zimbra-7-partner-info',
      name: 'Zimbra7PartnerInfo',
      component: Zimbra7PartnerInfo,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'zimbra-7-partner-info/new',
      name: 'Zimbra7PartnerInfoCreate',
      component: Zimbra7PartnerInfoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'zimbra-7-partner-info/:zimbra7PartnerInfoId/edit',
      name: 'Zimbra7PartnerInfoEdit',
      component: Zimbra7PartnerInfoUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'zimbra-7-partner-info/:zimbra7PartnerInfoId/view',
      name: 'Zimbra7PartnerInfoView',
      component: Zimbra7PartnerInfoDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'zimbra-7-partner-employee',
      name: 'Zimbra7PartnerEmployee',
      component: Zimbra7PartnerEmployee,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'zimbra-7-partner-employee/new',
      name: 'Zimbra7PartnerEmployeeCreate',
      component: Zimbra7PartnerEmployeeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'zimbra-7-partner-employee/:zimbra7PartnerEmployeeId/edit',
      name: 'Zimbra7PartnerEmployeeEdit',
      component: Zimbra7PartnerEmployeeUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'zimbra-7-partner-employee/:zimbra7PartnerEmployeeId/view',
      name: 'Zimbra7PartnerEmployeeView',
      component: Zimbra7PartnerEmployeeDetails,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'zimbra-7-application-settings',
      name: 'Zimbra7ApplicationSettings',
      component: Zimbra7ApplicationSettings,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'zimbra-7-application-settings/new',
      name: 'Zimbra7ApplicationSettingsCreate',
      component: Zimbra7ApplicationSettingsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'zimbra-7-application-settings/:zimbra7ApplicationSettingsId/edit',
      name: 'Zimbra7ApplicationSettingsEdit',
      component: Zimbra7ApplicationSettingsUpdate,
      meta: { authorities: [Authority.USER] },
    },
    {
      path: 'zimbra-7-application-settings/:zimbra7ApplicationSettingsId/view',
      name: 'Zimbra7ApplicationSettingsView',
      component: Zimbra7ApplicationSettingsDetails,
      meta: { authorities: [Authority.USER] },
    },
    // jhipster-needle-add-entity-to-router - JHipster will add entities to the router here
  ],
};
